$.datepicker.regional['fr'] = {
    clearText: 'Effacer',
    clearStatus: '',
    closeText: 'Fermer',
    closeStatus: 'Fermer sans modifier',
    prevText: '<Préc',
    prevStatus: 'Voir le mois précédent',
    nextText: 'Suiv>',
    nextStatus: 'Voir le mois suivant',
    currentText: 'Courant',
    currentStatus: 'Voir le mois courant',
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun',
        'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
    monthStatus: 'Voir un autre mois',
    yearStatus: 'Voir un autre année',
    weekHeader: 'Sm',
    weekStatus: '',
    dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    dayStatus: 'Utiliser DD comme premier jour de la semaine',
    dateStatus: 'Choisir le DD, MM d',
    dateFormat: 'dd/mm/yy',
    firstDay: 0,
    initStatus: 'Choisir la date',
    isRTL: false,
};

function changeVisu(field, path, evt) { }
function countItemFilter(input, translation) { }

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;
    var content_page = '';

    /**
     * Mise à jour des valeurs de pages d'origine pour le scroll infini
     */
    if ($('#scroll_origin').length) {
        if (!fromScrollBottom && fromScrollTop) {
            $('#scroll_origin').val(page);
        } else if (fromScrollBottom && !fromScrollTop) {
            $('#initial_page').val(page);
        } else {
            $('#scroll_origin, #initial_page').val(page);
        }
    }

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, page, null, null, fromScrollBottom, fromScrollTop);
    } else {
        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}

// Properly reset price filter slider on mobile
function resetExtraFilters() {
    var url = new URL(window.location.href);
    var searchParams = url.searchParams;
 
    if (searchParams.has("price")) {
        var min_price = $('input[name="initial_min_price"]').val();
        var max_price = $('input[name="initial_max_price"]').val();
 
        $('#slider-range .ui-slider-handle').eq(0).html(min_price);
        $('#slider-range .ui-slider-handle').eq(1).html(max_price);
 
        $('input[name="price_min"]').val(min_price);
        $('input[name="price_max"]').val(max_price);
 
        var $slider = $("#slider-range");
        $slider.slider("values", 0, min_price);
        $slider.slider("values", 1, max_price);
 
        url.searchParams.delete("price");
        window.history.replaceState({}, '', url.toString());
    }
}

function paginate(page) {
    var form = document.getElementById('filters_form'),
        total_element = parseFloat($('#totalElems').val()),
        nb_prod = parseFloat($('#nb_prod').val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        nb_prod_default = parseFloat($('#nb_prod_default').val()),
        pagination = $('.pagination'),
        see_all = parseFloat($('#see_all').val());

    page = page || 1;

    $.ajax({
        url: path_relative_root + create_link('ajax_reload_pagination'),
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {
            var productLoaded = $('.item_container').length;
            var percentLoaded = (productLoaded * 100 / total_element);

            pagination.each(function () {
                if (nb_prod < total_element) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product" onclick="seeXProduct(1000)">' + Translator.translate('see_more_products') + '</div>';
                } else if (total_element >= nb_prod_default) {
                    this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ')">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}
var eresa_no_stock = true;
var eresa_v2 = true;

// Eresa CTA : Checks if size selected then shows pop in eresa
function checkCartEresa() {

    var form = document.getElementById('item_add_form'),
        shad = document.getElementById('general_shade'),
        itm_id = (form.itm_id) ? form.itm_id.value : null,
        value = getInputVal.bind(form),
        head_offset = (window.innerWidth * 0.2921875),
        form_offset = $('.prod_page_bot', '#content_product').get(0).offsetTop,
        data, has_errors;
    if (!itm_id) {
        return false;
    }
    data = {
        mobile: 1,
        product_id: itm_id,
        size_id: 0,
        color_id: 0,
        need_color_id: true,
        need_size_id: true,
        is_model: value('itm_is_model')
    };

    has_errors = false;

    if ((data.need_color_id && (data.color_id === null || data.color_id === undefined)) || (data.need_size_id && (data.size_id === null || data.size_id === undefined))) {
        

        $('html, body').animate({scrollTop: form_offset - head_offset}, 400);

        if (data.color_id) {

            form.itm_colour.classList.add('error');
        }

        if (!data.size_id) {

            form.itm_size.classList.add('error');
        }
        return false;
    }
}

var eresa_v2 = true;
function eresaReady() {
    checkCartEresa();

    if (checkCartEresa() !== false) {
        Eresa.show();
    }
}

/* barre de recherche header mobile */
function searchToggle() {

    var top_search = $('#top_search'),
        main_menu_btn = $('#main_menu_btn'),
        search_home = $('#search_home'),
        shade = $('#shade');
        header = $('#site_head .banner_wrapper');

    if (top_search.hasClass('opened')) {
        shade.removeClass('forAccount forMenu visible');
        if (main_menu_btn.hasClass('menu_open')) {
            main_menu_btn.click();
            search_home.focus();
        } else {
            top_search.removeClass('opened');
            header.removeClass('opened');
            shade.removeClass('visible forSearch');
        }
    } else {
        if (main_menu_btn.hasClass('menu_open')) {
            main_menu_btn.click();
            top_search.addClass('opened');
            header.addClass('opened');
            search_home.focus();
            shade.addClass('forSearch');
            shade.removeClass('visible');
            shade.on('click', function () {
                if (!search_home.val()) {
                    searchToggle();
                }
            });

        } else {
            top_search.addClass('opened');
            header.addClass('opened');
            search_home.focus();
            shade.addClass('forSearch');
            shade.removeClass('forAccount forMenu visible');
            
            shade.on('click', function () {
                if (!search_home.val()) {
                    searchToggle();
                }
            });
        }
    }
}

function addFileInput2(myFile) {
    var file = myFile.files[0];
    var filename = file.name;
    $('.title-img').html(filename);
}

function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = new Array();

    $(".checkbox_select_product").each(function () {
        var element = $(this);
        var id = element.attr("data-id");

        /**
         * Sélectionne le bloc .product_ctn
         */
        var productCnt = $(this).closest('.product_ctn');

        if (element.is(":checked")) {
            var price = parseFloat(element.attr("data-price"));

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }

            productCnt.addClass('checked');
        } else {

            if (productCnt.hasClass('checked')) {
                productCnt.removeClass('checked');
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + " :";
            priceSpan.html(
                '<span id="total_price">0<span class="devise">' + siteCurrency.symbol_right + '</span><span class="decimal_price"></span><sup></sup></span>'
            );
            break;
        case 1:
            text = $texts[1];
        default:
            if (text == "") text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = "00";
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = "" + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + "0";
                }
            }

            priceSpan.html(
                '<span id="total_price">' +
                pricefloor +
                '<span class="decimal_price"><sup></sup>.' +
                decimal +
                '<span class="devise">' + siteCurrency.symbol_right + '</span></span></span>'
            );
            txt =
                '<span class="wishlist_count">' +
                selectedProducts.length +
                "</span> " +
                text +
                "";
    }

    $(".txt", "#nrArticlesSelected").html(txt);
}

function clearSearch() {
    var search_home = $('#search_home');
    if (!search_home.val()) {
        searchToggle();
    } else {
        search_home.val('');
        search_home.focus();
    }
}

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_uri_by_lang'),
        type: "post",
        data: { lang: locale, url: uri, site: site },
        success: function (data) {
            if (data != "") {
                var force = "?force";
                var href_lang = data + force;
                location.href = href_lang;
            }
        },
    });
}

var content_meta_lang = $('meta[http-equiv="content-language"]').attr('content');

if (content_meta_lang == 'en-gb') {
    $('body').addClass('uk');
    setTimeout(function () {
        $('.wrapper_wishlist .euro_dollar_devise').empty().html(siteCurrency.symbol_right);
    }, 550);
}

//SWITCH LANG
function showLayer(type = 'lang') {
    const showLayerdropdown = $('#dropdown-content-' + type);
    const showLayershadDropdownMenu = $('.shadDropdownMenu');

    if (showLayerdropdown.hasClass('show')) {
        showLayerdropdown.removeClass('show');
        showLayershadDropdownMenu.removeClass('active');
    } else {
        showLayerdropdown.addClass('show');
        showLayershadDropdownMenu.addClass('active');
    }

    showLayershadDropdownMenu.on('click', function() {
        showLayerdropdown.removeClass('show');
        showLayershadDropdownMenu.removeClass('active');
    });
}

function toggleMenuExtra() {
    $("#left_menu .general_menu .list_menu").overlayScrollbars({});
}

$(function () {
    $('.js-scrollTo, .cta--secondary.coffret').on('click', function () { // Au clic sur un élément
        var page = $(this).attr('href'); // Page cible
        var speed = 750; // Durée de l'animation (en ms)
        $('html, body').animate({ scrollTop: $(page).offset().top }, speed); // Go
        return false;
    });

    /* keep gift message value in session storage */
    // Get the text area element
    let $textAreaMobile = $("#mot_personnalise>textarea");

    // Check if data is already stored in local storage
    if (sessionStorage.getItem("textAreaMobileData")) {
        // If data is stored, retrieve it and set it as the value of the text area
        $textAreaMobile.val(sessionStorage.getItem("textAreaMobileData"));
    }

    // Listen for changes to the text area
    $textAreaMobile.on("input", function () {
        // Store the new data in local storage
        sessionStorage.setItem("textAreaMobileData", $textAreaMobile.val());
    });

    // ANCRE FP CONTENU COFFRET
    $('.associations').prop('id', 'associations');
    $('.cta--secondary.coffret').prop('href', '#associations');

    // MESSAGE PERSONALISE EFFET DEPLOIEMENT TUNNEL
    $('.mot_personnalise_form span').on('click', function () {
        $(this).toggleClass('arrow_close');

        if ($(this).hasClass('arrow_close')) {
            // $('#mot_personnalise').slideUp("speed");
            $('input[name=add_mot_perso]').val('no');
            $('#mot_personnalise').css('display', 'none');
        } else {
            $('#mot_personnalise').slideDown("slow");
            $('input[name=add_mot_perso]').val('ok');
        }
    });

    /* Primary font - Adobe */
    $('head').append('<link rel="stylesheet" href="https://use.typekit.net/ihv8lvu.css">');

    // Account dropdown from menu
    if ($('#left_menu .bloc_lnk.user.connected.dropdown').length > 0) {
        $(document).on('click tap touch', '.bloc_lnk.user.connected.dropdown', function (e) {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).find('.dropdown.mon-compte').slideUp(300);
            } else {
                $(this).addClass('active');
                $(this).find('.dropdown.mon-compte').slideDown(300);
            }
        });
    }

    // click vue 1 item rayon
    $('#oneProduct').click(function () {
        $('.wrapper_bandeau').addClass('full');
    });

    $('#twoProducts').click(function () {
        $('.wrapper_bandeau').removeClass('full');
    });

    // Footer accordion
    $('.SEO .col_title').each(function () {
        $(this).click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.col_content').removeClass('active').slideUp(300);
            } else {
                $('.SEO .col_title').removeClass('active');
                $('.SEO .col_content').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).parent().find('.col_content').addClass('active').slideDown(300);
            }
        });
    });

    // bandeauMenuAffix
    setTimeout(function () {
        if ($(".bandeauMenuAffix")) {
            $("<div id='close_btn'></div>").appendTo(".bandeauMenuAffix");

            $("#close_btn").on("click tap touch", function () {
                $(".closeBtn.close_delais").click();
                $(".bandeauMenuAffix").remove();
                $("#contents_wrapper").css("margin-top", "-3.55%");
            });
        }
    }, 600);

    // PREHEADER SWIPER
    if ($('#sliderBando').length && ($('#sliderBando .swiper-slide').length > 1)) {
        setTimeout(function () {
            new Swiper('#sliderBando.swiper-container', {
                slidesPerView: 1,
                loop: true,
                autoplay: 2500,
                speed: 2000
            });
        }, 500);
    }

    /**
     * SWIPER COVER MODULE HP
     */
    if ($('.coverHomeSwiper').length && ($('.coverHomeSwiper .swiper-slide').length > 1)) {
        var coverHome_swiper = new Swiper('.coverHomeSwiper', {
            autoplay: 5000,
            slidesPerView: 1,
            loop: true,
            speed: 1000,
            pagination: ".pagination-cover-home",
            paginationClickable: true,
        });
    }

    /**
     * Swiper module cover carousel 
     */
    $(".cms-module.cover-module .coverSwiper").each(function() {
        // Find specific nav buttons in case more than one swiper is on the page
        var thisPagination = $(this).parent().find('.swiper-pagination');

        var adv_cover_swiper = new Swiper($(this), {
            paginationClickable: true,
            pagination: thisPagination,
            spaceBetween: 20,
            slidesPerView: "auto",
            freeMode: true,
        })
    });

    /**
     * Each TG home sliders VUE DESKTOP
     */
    $('.tgHomeSwiper').each(function (index) {
        if ($(this).parent().parent().parent().attr('data-title') !== undefined) {
            // Sur la homepage
            var cssName = $(this).parent().parent().parent().attr('data-title');
        } else {
            // Sur les pages ADV
            var cssName = $(this).parent().parent().attr('data-title');
        }

        if ($('.tg-module.' + cssName + ' .tgHomeSwiper').length && ($('.tg-module.' + cssName + ' .tgHomeSwiper .swiper-slide').length > 1)) {
            var tg_swiper = new Swiper($('.tg-module.' + cssName + ' .tgHomeSwiper'), {
                autoplay: 5000,
                slidesPerView: 1,
                loop: false,
                speed: 1000,
                pagination: ".tg-module." + cssName + " .pagination-tg-home",
                paginationClickable: true
            });
        }

        // clone price achat express
        $('.tg-module.' + cssName + ' .tgHomeSwiper .swiper-slide').each(function () {
            var add_basket_type;
            var data_add_basket_type = $(this).find('.button.achat.valid').attr('data-is-preco');
            var add_basket_preco_trad = Translator.translate('add_basket_preco');
            var productPrice = $(this).find('.price_tag');

            if (data_add_basket_type === "1") {
                add_basket_type = add_basket_preco_trad;

                $(this).find('.form_submit .button.achat.valid span').html(add_basket_type);
            } else {
                productPrice.clone().insertAfter($(this).find('.button.achat.valid span:first-child'));
            }
        });
    });

    /**
     * SWIPER NAV RAYON
     */
    if ($('#navCategorySwiper').length && ($('#navCategorySwiper .swiper-slide').length > 1)) {
        var navCategory_swiper = new Swiper('#navCategorySwiper', {
            slidesPerView: '1.5',
            centeredSlides: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev'
        });
    }

    var $deja_compte = Translator.translate('existing_user_pwd_reset_' + site_id);
    $(".login_form_wrapper .w-newlogin-form").append($('<p class="deja_compte">' + $deja_compte + '</p>'));

    /***** NAV CATEGORY SWIPER *****/
    if ($('#navCategorySwiper').length) {
        var currentCat = $('#filters_form #object_id').val();
        $('#navCategorySwiper .swiper-slide').each(function () {
            if ($(this).data('idobj') == currentCat) {
                $(this).addClass('actif');
            }
        });

        setTimeout(function () {
            navCategory_swiper.slideTo($('#navCategorySwiper .swiper-slide.actif').index());
        }, 500);
    }

        /** MODULE GONDOLE INTEREST ACHAT EXPRESS ADD BASKET */
    if ($("#gondole_interest").length) {
        new Swiper("#gondole_interest", {
            observeParents: true,
            observer: true,
            slidesPerView: 2.5,
            spaceBetween: 20,
        });
    }

    if ($(".cartAssocSwiper").length) {
        new Swiper(".cartAssocSwiper", {
            observeParents: true,
            observer: true,
            slidesPerView: 'auto',
            spaceBetween: 20,
        });
    }

    if (content_meta_lang == 'en-gb') {
        $('.wish_liste_product .check, #selectAll, #unselectAll, .choix_delivery_postal .checkbox').click(function () {
            setTimeout(function () {
                $('.wrapper_wishlist .euro_dollar_devise').empty().html(siteCurrency.symbol_right);
            }, 300);
        });
        $('.product_del, .wishToBasket').click(function () {
            setTimeout(function () {
                $('.wrapper_wishlist .euro_dollar_devise').empty().html(siteCurrency.symbol_right);
            }, 300);
        });
    }

    $('.text-image .block-txt').prependTo('.text-image.container');
    $('#btn_add_wishlist').prependTo('.wrapper_btn_top');
    $('.customer .btn-product').insertAfter('.swiper-pagination');
    $('#selectAll').prependTo('.wrapper_btn_top');
    $('#unselectAll').prependTo('.wrapper_btn_top').hide();
    $('#nrArticlesSelected').prependTo('.wrapper_btn_top');
    $('.loader_container #filters_form_submit').prependTo('.btn_container .form_submit');
    $('.upselling_panier progress').prependTo('.upselling_panier');
    $('.text-no-container').next().children('.home-module-contents').children('.btn-product ').css('display', 'none');
    $('#mes_err').insertAfter('.intl-tel-input');

    setTimeout(function () {
        if ($('#qteProd option').hasClass('no_stock')) {
            $('.twin_fields').css('display', 'none');
        }
    }, 500);

    $(".choix_delivery_postal").each(function (e) {
        var $price = $(this).find('.ship_price');
        var $target = $(this).find('.secondary');
        $price.insertAfter($target);
    });

    $(".choix_delivery_postal").each(function (e) {
        var $price = $(this).find('.ship_price_offered');
        var $target = $(this).find('.secondary');
        $price.insertAfter($target);
    });

    $("#selectAll").click(function (ev) {
        $(".check").each(function () {
            var $target = $(this).add($(this).siblings(".wrapper_product"));
            var $parent = $(this).parent(".wrapper_cadre_product");
            $target.addClass("checked");
            $parent.addClass("checkedBox");
        });
        $(this).css('display', 'none');
        $(this).prev().css('display', 'block');
        scanForChanges();
    });

    $("#unselectAll").click(function (ev) {
        $(".check").each(function () {
            var $target = $(this).add($(this).siblings(".wrapper_product"));
            var $parent = $(this).parent(".wrapper_cadre_product");
            $target.removeClass("checked");
            $parent.removeClass("checkedBox");
        });
        $(this).css('display', 'none');
        $(this).next().css('display', 'block');
        scanForChanges();
    });

    // Inits FP
    if ($('body').hasClass('product_page')) {

        $(document).ajaxComplete(function () {
            // On bouge la wishlist de la FP
            if ($('.prod_wishlist_btn').length) {
                $('.prod_wishlist_btn').appendTo($('#product_name'));
            }

            // Initialisation des gondoles
            if ($('.assos_product .swiper-slide').length > 1) {
                new Swiper('.assoc_carousel_wrapper .assos_product', {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    loop: false,
                    pagination: '.assos_product .swiper-pagination',
                    paginationClickable: true
                });
            }

            if ($('.assos_theme .swiper-slide').length > 1) {
                new Swiper('.assoc_carousel_wrapper .assos_theme', {
                    slidesPerView: 1,
                    loop: false,
                    pagination: '.assos_theme .swiper-pagination',
                    paginationClickable: true
                });
            }

            // Affichage du header seulement si on passe les images
            setTimeout(function () {

                if ($('#zoom_container').length) {
                    var elHeight = $('#img_large').height();

                    if ($(window).scrollTop() > elHeight) {
                        $("#site_head").addClass('show_header');
                    } else {
                        $("#site_head").removeClass('show_header');
                    }

                    $(window).on('scroll', function () {
                        if ($(window).scrollTop() > elHeight) {
                            $("#site_head").addClass('show_header');
                        } else {
                            $("#site_head").removeClass('show_header');
                        }
                    });
                }
            }, 500);
        });
    }

    // Page cadeau d'entreprise
    if ($('.cadeau_entreprise').length) {
        // Affichage de la lightbox des contacts
        if ($('#lightbox_nos_contacts').length && $('.cta_nos_contacts').length) {
            $(window).on("scroll", function () {
                var headerH = $('.banner_wrapper').height();
                var shHeaderH = $('.banner_nav').innerHeight();

                if ($(window).scrollTop() != 0 && !$('body').hasClass('search_hidden')) {
                    $('.cta_nos_contacts').css('top', (headerH + 20) + 'px');
                } else if ($(window).scrollTop() != 0 && $('body').hasClass('search_hidden')) {
                    $('.cta_nos_contacts').css('top', (shHeaderH + 20) + 'px');
                } else {
                    $('.cta_nos_contacts').css('top', '-100%');
                }
            });
        }

        // Init swiper cadeau d'entreprise
        if ($('.swiper_catalogue .swiper-slide').length > 1) {
            var swiper_catalogue = new Swiper('.swiper_catalogue', {
                slidesPerView: 1,
                spaceBetween: 20,
                pagination: ".pagination-cover-catalogue",
                paginationClickable: true,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev'
            });
        }

        // Init swiper cadeau d'entreprise catalogue prices
        if ($('.cat_catalogue .swiper-slide').length > 1) {
            var swiper_catalogue = new Swiper('.cat_catalogue', {
                slidesPerView: 1,
                spaceBetween: 20,
                pagination: ".cat_pagi",
                paginationClickable: true,
                nextButton: '.swiper-button-next.cat_next',
                prevButton: '.swiper-button-prev.cat_prev'
            });
        }

        if ($('.swiper_offres .swiper-slide').length > 1) {
            var swiper_offres = new Swiper('.swiper_offres', {
                slidesPerView: 1,
                spaceBetween: 20,
                pagination: ".pagination-cover-offres",
                paginationClickable: true,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev'
            });
        }
    }

    // Page contact
    if ($('body').hasClass('contact_faq') && $('.w-input-element[name="form_service"]').length) {
        var sService = $('.w-input-element[name="form_service"]');
        var fNumCmd = $('.w-contact-form #divContactNumCommande');

        sService.on('change', function () {
            if ($(this).val() == 1) {
                fNumCmd.show();
            } else {
                fNumCmd.hide();
            }
        });
    }

    // Page store locator
    if ($('body').hasClass('store_locator') || $('body').hasClass('satellite_store_details')) {
        $('html').css('font-size', 'inherit');

        if ($('#sliderBando').length) {
            $('body').addClass('calibrate_body');
        }

        if ($('.img_store .swiper-slide').length > 1) {
            var img_store = new Swiper('.img_store ', {
                slidesPerView: 1,
            });
        }
    }

    $('#btn_cmd_valid').on('click', function (evt) {
        if ($('body.step_2').length && $('#order_delivery_date').length) {
            let conveyorId = $('#conveyor_id').val();
            let currentDeliveryDate = $('#delivery-date-' + conveyorId).datepicker("getDate");
            currentDeliveryDate = $.datepicker.formatDate("yy-mm-dd", currentDeliveryDate);
            if (!currentDeliveryDate) {
                $('#order_delivery_date').val('');
            }

            if ($('#order_delivery_date').length) {
                let order_delivery_date = $('#order_delivery_date').val();

                if (!order_delivery_date) {
                    let deliveryData = arrayDeliveryData[conveyorId] || {};
                    let isPreco = deliveryData.isPreco || '';
                    let firstDateFound = determinesFirstAvailableDeliveryDate(conveyorId, isPreco);
                    let verifDate = firstDateFound ? verifyDateIntegrity(firstDateFound, conveyorId) : false;
        
                    if (firstDateFound && (verifDate === true || (Array.isArray(verifDate) && verifDate.length === 1 && verifDate[0] === true))) {
                        $('#order_delivery_date').val(firstDateFound.toISOString().split('T')[0]);
                    } else {
                        evt.preventDefault();
                        $('.bloc_livraison .active .order_delivery_date').css('border', 'red 1px solid');
                        $('.bloc_livraison .active .block-delivery-date .err_delivery_date').html(Translator.translate('err_delivery_date')).show();
                        scrollToErrorDate();
                        $('.err_delivery_date').scrollTop();
                        return false;
                    }
                } else {
                    let verifDate = verifyDateIntegrity(order_delivery_date, conveyorId);
                    
                    if (!verifDate) {
                        evt.preventDefault();
                        $('.bloc_livraison .order_delivery_date').css('border', 'red 1px solid');
                        $('.bloc_livraison .block-delivery-date .err_delivery_date').html(Translator.translate('err_delivery_date')).show();
                        scrollToErrorDate();
                        return false;
                    }
                }
            }
        }
    });

    handleDispatchPopup();

    filtre_ajax();

    // clone price achat express
    $('.assos_product li, .assos_theme li').each(function () {
        var add_basket_type;
        var data_add_basket_type = $(this).find('.button.achat.valid').attr('data-is-preco');
        var add_basket_preco_trad = Translator.translate('add_basket_preco');
        var productPrice = $(this).find('.price_tag');

        if (data_add_basket_type === "1") {
            add_basket_type = add_basket_preco_trad;
            $(this).find('.form_submit .button.achat.valid span:first-child').html(add_basket_type);
        } else {
            productPrice.clone().insertAfter($(this).find('.button.achat.valid span:nth-child(1)'));
        }
    });
    /* MULTI DESTINATION DELIVERY */
    if ($('body.cart').length) {
        var filAriane = $('#ariane_home');
        if ($('.choix_delivery_multiple.bloc_livraison').length) {
            filAriane.hide();
        }
    }
    if ($('body.cart.step_1').length) {
        if ($('.restricted_form').length) {
            $('.mot_personnalise_form').addClass('active');
        }
    }

    // Add info texte for input "Date de naissance" on customer info page
    if ($('body.customer').length && $('#clientForm').length) {
        let textDateDeNaissance = '<span class="nb_client_text_naissance">' + Translator.translate('texte_date_de_naissance') + '</span>'
        $('#clientForm input#clientDatenais').after(textDateDeNaissance);
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            }, 200);
        }
    });

    if ($("body.newsletter.mobile").length) {
        $("<div class='newsletter_text'>" + Translator.translate( 'newsletter_text_mob') + "</div>").insertBefore($("body.newsletter.mobile .newsletter.satellite_mobile #subscriptionForm .loader_container"));
        $("<div class ='img_newsletter'><img src='../img/newsletter/pm_patisserie_mob.png'><img src='../img/newsletter/pm_rentree_mob.png'><img src='../img/newsletter/ps_rs_02.png'></div>").insertAfter($(this).find('.newsletter.satellite_mobile'));
    }
    
    if ($('.transporteur_retrait').length && $('.transporteur_retrait .ship_price_offered').length && $('.transporteur_retrait .shipping_address').length) {
        var target_position = $('.transporteur_retrait .shipping_address');

        $('.transporteur_retrait .ship_price_offered').insertAfter(target_position);
    }

    // toggle page CSM
    $(".dropdown_cms").click(function() {
        $(this).next('ul.menu_cms').slideToggle();
    });

    if ($('body.cms_page_new').length) {
        // resize words that are too long to fit in CMS cover-module
        setTimeout(() => {
            cmsAutoFontResize('.cms-module.cover-module .cms-column.boxText', 10, 8.411, 42);

            // Attach a resize listener to the body
            $(window).on('resize', function () {
                cmsAutoFontResize('.cms-module.cover-module .cms-column.boxText', 10, 8.411, 42);
            });
        }, 1500);
    }

    setTimeout(updateFidelityPoints, 500);
    $(document).on('change', 'input[type=radio]', updateFidelityPoints);
    $("#fidelity_program").on('change', function(event) {
        joinFidelityProgram.call(this, event);
    });
});

/**
 * On delivery-choice page update fidelity points according the selected delivery method
 */
function updateFidelityPoints() {
    if ($("body").hasClass("step_2") && $("#fidelity_points").length) {
        let fdp = 0;
        if ($('input[type=radio]:checked').length) {
            fdp = $('input[type=radio]:checked').data('fdp');
        }
        let amount = $("#fidelity_points").data('amount');
        $("#fidelity_points").html(Math.ceil(fdp + amount));
    }
}

/**
 * Join fidelity program on fidelity program checkbox change (cart page and delivery-choice page)
 * @param e event
 */
function joinFidelityProgram(e) {
    e.preventDefault();
    e.stopPropagation();
    let from = '';
    if ($("body").hasClass("cart step_1")) {
        from = 'panier';
    } else if ($("body").hasClass("cart step_2")) {
        from = 'delivery-choice';
    }
    if (this.checked) {
        $.ajax({
            type: 'post',
            data: {
                optinFid: 1,
                from: from
            },
            url: path_relative_root + 'ajax_join_fidelity_program.php',
            success: function (res) {
                let message = '';
                if (res.success) {
                    $(".fidelity_program_checkbox").slideUp();
                    if ($("body").hasClass("cart step_1")) {
                        message = Translator.translate('fidelity_program_joined');
                    }
                } else if (!res.redirect_login && $("body").hasClass("cart step_1")) {
                    message = Translator.translate('fidelity_program_error');
                }
                $(".fidelity_program_response").html(message);
            }
        });
    }
}

//tunnel error date scroll to function custom event
window.addEventListener("errorDateDetected", function(event) {
    scrollToErrorDate();
});

if (($('body').hasClass('customer') || $('body').hasClass('newsletter') || $('body').hasClass('wishlist') || $('body').hasClass('order')) && $('#menu_account_slider_mob').length > 0) {

    var menuInitialSlide = 0;

    $('.menu_account_slider_mob').css({ 'display': 'block', 'opacity': '1' });

    $('.menu_account_slider_mob .swiper-slide').each(function (i, obj) {
        if ($(obj).hasClass('activeMenu')) {
            menuInitialSlide = i;
        }
    });

    var menuAccSwiperMob = new Swiper('#menu_account_slider_mob', {
        slidesPerView: 2.5,
        centeredSlides: true,
        initialSlide: menuInitialSlide,
        slidesOffsetBefore: 30,
        slidesOffsetAfter: 30,
        onInit: function () {
            $('.menu_account_slider_mob').fadeTo("slow", 1);
        },
    });
}

//tunnel error date scroll to function
function scrollToErrorDate() {
    if ($('.multidelivery_product_container').length) {
        var errorElement = $('.multidelivery_product_container .err_delivery_date:visible').first().parents('.multidelivery_product_container');
    } else {
        var errorElement = $('.err_delivery_date:visible').first().parents('.bloc_livraison');
    }
    var scrollAdjustment = errorElement.offset().top - 130;
    if (errorElement.length) {
        $('html, body').animate({
            scrollTop: scrollAdjustment
        }, 500);
    }
}

function clickMore(i) {
    $('.block_services_'+i+' .block_services_txt').toggleClass('open');
    $('.block_services_'+i+' .see_more').remove();
}

// FAQ
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {
        showTabFaq();
        var search = $(this).val();
        if (search && search.length > 3) {
            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {
                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }
            });
            $('.brand_item:not(.hasMatch)').hide();
            if (array_match.length == 0) {
                $('.search_results').hide();
            }
        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });
}

function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function () {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");

        setTimeout(function () {
            $("html, body").animate({ scrollTop: $(".wrapper_moncompte_main.faq .faq_question_" + question_id).offset().top - 300 }, 500);
        }, 500);

    } else {

        var parent_id = $(".faq_theme_" + node_id).data("parent");

        //active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        //show questions
        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function () {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function (idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function () {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function () {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function () {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });

        if (node_id !== undefined) {
            setTimeout(function () {
                $("html, body").animate({ scrollTop: $(".wrapper_moncompte_main.faq .faq_element.active").offset().top - 300 }, 500);
            }, 500);
        }
    }
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({ scrollTop: $(".faq_question_" + question_id + "").offset().top - 300 }, 500);
}

/* PAGE RAYON */
function filtre_ajax() {
    if ($('.item_container').length) {
        $('.item_container').each(function () {
            var add_basket_type;
            var data_add_basket_type = $(this).find('.button.achat.valid').attr('data-is-preco');
            var add_basket_preco_trad = Translator.translate('add_basket_preco');
            var id_product = $(this).attr('data-prod');
            var productPrice = $('#i' + id_product).find('.price_tag');

            if (data_add_basket_type === "1") {
                add_basket_type = add_basket_preco_trad;
                $(this).find('.form_submit .button.achat.valid span').html(add_basket_type);
            } else {
                productPrice.clone().insertAfter($(this).find('.button.achat.valid span:first-child'));
            }
        });
    };
}

function deleteItemFromCart (id, qty) {
    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && id !== null && id !== undefined) {
        DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, id, "remove_from_cart", qty);
    }
}

function hideModalAction(evt) {

    var shade = $('#shade');
    var modal = $(".cart_item_actions");

    if (typeof evt !== "undefined") evt.preventDefault();

    shade.off('touchend', modBoxClose)
        .removeClass('visible');

    modal.removeClass('actif').removeClass('back-shade');
    moveSacheterieOption();
}

function moveSacheterieOption() {
    if ($("body.cart.step_1 .order_gift").length) {
        $("body.cart.step_1 .order_gift").appendTo('label[for="is_order_gift"]');
    };

    if ($('body.cart.step_1 .order_gift_form label[for="isGift"]').length) {
        $('.order_gift_form .form_item').css("padding-bottom","3.5rem");
    } else {
        $('.order_gift_form .form_item').css("padding-bottom","0rem");
    }
}

/**
 * Permet d'ajour un produit à la wishlist via le rayon (ou la recherche)
 * Utilisation : _app\views_mobile_new\_components\product\achat_express.php
 */
function addToWishlistRay() {
    // On utilise le même que dans la fiche produit car cela fait la même chose
    // mais on donne un nom différent au cas où
    addToWishlist.call(this);
}

// ADRESSES
// Liste d'adresses
if ($(".customer.mobile .customer_adress .conteneur_adresse").length) {
    var adrType = $(".customer.mobile .titre_adresse .adr_type")
    var custName = $(".customer.mobile .content .separation strong");
    var adr = $(".customer.mobile .content .separation .adresse_full")
    var number = $(".customer.mobile .content .separation .adresse_tel");

    // Remove civilité and capitalize name in line with maquette
    custName.each(function () {
        if (custName.text().includes("M. ")) {
            $(this).text($(this).text().replace("M. ", ""));
        } else if (custName.text().includes("Mme ")) {
            $(this).text($(this).text().replace("Mme ", ""));
        }

        var nameText = $(this).text().toLowerCase();
        $(this).html(nameText);
    });

    // Add "-" between address and post code, and capitalize city/region
    adr.each(function () {
        $(this).text($(this).text().replace(`${$(this).text()}`, `${$(this).text()} -`));

        var adrText = $(this).next("p").text().toLowerCase();
        $(this).next("p").html(adrText);
    });

    // Add spaces between (French) numbers in line with maquette
    if (number) {
        number.each(function () {
            $(this).text($(this).text().replace(/(^(\S{3})(\d)(\d{2})(\d{2})(\d{2})(\d{2})$)/g, '$2 $3 $4 $5 $6'));
        });
    }

    $(".customer.mobile .customer_adress .conteneur_adresse").css("opacity", "1");

    // add class for style switch on / off
    $(".customer.mobile .customer_adress .wrapper_form_adresse .fact_choice").addClass('switch-on-off');

}

// COMMANDES
// Add "Prix" in order recap
if ($(".order.mobile .cart_item_price.price_container").length) {
    $(".order.mobile .cart_item_price.price_container").prepend("<div class='cart_price_txt'><p>" + Translator.translate('cart_price') + "</p></div > ");
    $(".order.mobile .content_commandes").css("opacity", "1");
}

// Remove facture
if ($(".order.mobile .recap_shipping .first_part .address_info span:contains(Annulée)")) {
    $(".order.mobile .elem_tunnel .facture-bloc").css("display", "none");
    $(".customer.mobile .clearfix.mes_messages .order_recap").css("margin-top", "30px");
}

//MESSAGES
// Add introductory text
if ($(".customer.mobile .clearfix.mes_messages").length) {
    $(".customer.mobile .clearfix.mes_messages").prepend("<div class='msg_intro'><p>" + Translator.translate('intro_mes_messages') + "</p></div>");
}

// Remove recap
if ($(".customer.mobile .clearfix.mes_messages .main_wrapper_messages").length > 1) {
    $(".customer.mobile .clearfix.mes_messages .wrapper_detail_message .clearfix.content .clearfix").next(".recap_msg").css("display", "none");
}

// Popin Précommande
//@ Function to close lightbox_preco
function close_lightbox_preco() {
    $('.cart .lightbox_preco').removeClass('actif');
}

if ($(".cart .lightbox_preco").length) {
    //show lightbox_preco
    $('.cart .lightbox_preco').addClass('actif');

    //suppr 2eme choix
    $('.cart .lightbox_preco .form_item:last-child').remove();

    //1er choix checked
    $(".cart .lightbox_preco .form_item input[name='preco_choice']").attr('checked', true);

    //open shade
    $("#shade").addClass('actif precommande').show();
    $("#shade").css('opacity', 1);

    $("#shade").on("click tap touch", function () {
        $("#shade").removeClass('actif precommande').fadeOut();
        // function _app
        close_lightbox_preco();
    });
}

function triggerShad(shad, action) {
    shad = shad ? ('#shad_' + shad) : '#shad';

    if (action && action == 'add') {
        $(shad).addClass('actif');
    } else if (action) {
        $(shad).removeClass('actif');
    } else {
        return;
    }
}

function closeMultiShad(id) {
    modBoxClose.call(document.getElementById(id));
    $("#" + id).removeClass('actif');
    $("#" + id).fadeOut();
    $('#general_shade').fadeOut();
    $('#shade').fadeOut();

    if (id == "lightbox_nos_contacts") {
        $("#shade").removeClass('for_lb_contacts');
    }
}

function close_popup(elem) {
    closeMultiShad(elem);
}

function openMultiShad(id) {
    $("#" + id).addClass('actif').show();
    $("#shade").addClass('actif').show();

    $("#shade").on("click", function () {
        closeMultiShad(id);
    });

    if (id == "lightbox_nos_contacts") {
        $("#shade").addClass('for_lb_contacts');
        $("#lightbox_nos_contacts .wrap_contacts").overlayScrollbars({});
    }

    if (id == 'popup_dispatch') {
        $("#shade").addClass('dispatch_shade');
    }

    // Ask for a quote
    if (id == 'lightbox_devis_form') {
        setTimeout(function () {
            $('html, body').animate({
                scrollTop: 0
            }, 800);
        }, 150);
    }
}

alert = (function () {
    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {
        shade = document.getElementById("shade");
        albox = document.getElementById("alert_box");
        alert_tmpl = albox.innerHTML.trim();
        window.removeEventListener("load", init);
    }

    function closeAlertBox() {
        modBoxClose.call(document.getElementById('alert_box'));
    }

    function alert(str) {
        $(".alert_box_content", albox).html(str);
        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);
        modBoxOpen.call(document.getElementById('alert_box'));
    }

    window.addEventListener("load", init);

    return alert;
})();

function renewOrder(elem, orderId) {
    elem.classList.add('inactive');

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_baskets_from_order'),
        data: {
            orderId: orderId,
        },
        success: function (response) {
            let data = JSON.parse(response);

            var reqPromises = data.map(function (basket) {
                return $.get({
                    url: path_relative_root + create_link('ajax_update_basket'),
                    data: {
                        idprod: basket.produit_id,
                        panierid: 0,
                        qte: basket.panier_qte,
                        idcouleur: basket.produit_couleur,
                        idtaille: basket.produit_taile,
                        renewOrder: true,
                        dataTypeReturn: 'json',
                    },
                });
            });

            Promise.all(reqPromises).then(function (results) {
                var modbox = 'item_error';
                let errorOutOfStock, productAdded = false;
                let cart_btn = document.getElementById('cart_btn');
                let quantityAdded = 0;

                results.forEach((res) => {
                    let data = JSON.parse(res);

                    if (!data.error) {
                        productAdded = true;
                        if (data.nb_article != '') {
                            quantityAdded += parseInt(data.qte_prod_int);
                        }
                    } else if (data.error && data.error.code == 1) {
                        errorOutOfStock = true;
                    }
                });

                if (errorOutOfStock || results.length == 0) {
                    $('.order_error').remove();
                    $('.wrapper_cmd[data-order=' + orderId + '] .renew_order').after('<span class="order_error">' + Translator.translate('renew_order_out_of_stock') + '</span>');
                    $('.renew_order').before('<span class="order_error">' + Translator.translate('renew_order_out_of_stock') + '</span>');
                }

                if (productAdded) {
                    modbox = 'item_added';
                    modBoxOpen.call(document.getElementById(modbox));

                    if (cart_btn) {
                        cart_btn.classList.add('count');
                        cart_btn.classList.remove('none');

                        //Cas ou il n'y a pas encore de produit défini dans data-cart
                        if (!cart_btn.getAttribute('data-cart')) {
                            cart_btn.setAttribute('data-cart', parseInt(quantityAdded));
                        } else {
                            //On récupère le nombre de produits total dans le panier
                            var initialCount = cart_btn.getAttribute('data-cart');
                            //Et on ajoute à ce total la quantité choisie
                            var finalCount = parseInt(initialCount) + parseInt(quantityAdded);
                            cart_btn.setAttribute('data-cart', finalCount);
                        }
                    }
                }

                elem.classList.remove('inactive');
            });
        },
    });
}

/**
 * @description Used to switch lang and site with the pop-up
 *
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-11-04
 *
 * @return  void
 */
function switchLocale() {
    var site = ($('#switch_currency').length) ? $('#switch_currency').val() : '1';
    var lang = ($('#switch_lang').length) ? $('#switch_lang').val() : 'fr';

    // we set the new cookie
    createCookie('cookieDispatch', site + '-' + lang, 395)

    // we retrieve the {fr/en/nl} from the current URL
    var url = window.location.pathname.substring(3);

    // if we choose the same site and lang
    if (site_id == site && lang_iso == lang) {
        closeMultiShad('popup_dispatch');
        shadCookie.addClass("actif");
        cookies_bloc_lightbox.removeClass("cache");
        cookies_bloc_lightbox.addClass("notAccepted");
    } else {
        changeLocale(lang, url, site);
    }
}

/**
  * @description Changing flag lang depending on the choice
  *
  * @author  Rémy P  <rp@wshop.com>
  * @since   2022-11-07
  *
  * @return  void
  */
function updateLang() {
    if ($('#switch_lang').length) {
        var selectedLang = $('#switch_lang').val();
        if (selectedLang.length) {
            $('#switch_lang').css('background', 'transparent url(' + path_relative_root.substring(0, (path_relative_root.length - 3)) + 'svg/countries/' + selectedLang + '.svg) no-repeat 1rem center');
        }
    }
}

/**
  * @description Changing flag currency depending on the choice
  *
  * @author  Rémy P  <rp@wshop.com>
  * @since   2022-11-07
  *
  * @return  void
  */
function updateCurrency() {
    var selectCurrency = $('#switch_currency');
    var selectedCurrency = selectCurrency.val();
    var selectLang = $('#switch_lang');
    var disableSelectLang = false;
    var defaultCurrency = selectedCurrency;
    var defaultLang = selectLang.val();

    switch (selectedCurrency) {
        case '1':
            defaultCurrency = 'ue';
            defaultLang = 'fr';
            disableSelectLang = false;
            // we show the arrow
            $('.popup_dispatch_row_2 .popup_dispatch_arrow').show();
            break;

        case '2':
            defaultCurrency = defaultLang = 'en';
            disableSelectLang = true;
            // we show the arrow
            $('.popup_dispatch_row_2 .popup_dispatch_arrow').hide();
            break;
    }

    selectCurrency.css('background', 'transparent url(' + path_relative_root.substring(0, (path_relative_root.length - 3)) + 'svg/countries/' + defaultCurrency + '.svg) no-repeat 1rem center');
    selectLang.css('background', 'transparent url(' + path_relative_root.substring(0, (path_relative_root.length - 3)) + 'svg/countries/' + defaultLang + '.svg) no-repeat 1rem center');
    selectLang.prop('disabled', disableSelectLang);
    selectLang.val(defaultLang);

    // switch lang
    if ($('#switch_lang').length) {
        $('#switch_lang option').each(function (k, v) {
            if ($(v).hasClass('lang_' + selectedCurrency)) {
                $(v).show();
            } else {
                $(v).hide();
            }
        });
    }
}

/**
  * @description Handle the display of the dispatch pop-up
  *
  * @author  Stefane DR  <sdr@wshop.com>
  *
  * @return  void
  */
function handleDispatchPopup() {
    $(document).ajaxComplete(function (event, request, settings) {
        if (settings.url && settings.url.includes('ajax_check_cookie') && !document.cookie.match(/^(.*;)?\s*cookieDispatch\s*=\s*[^;]+(.*)?$/)) {
            // we hide the cookie bottom bar
            shadCookie.removeClass("actif");
            cookies_bloc_lightbox.addClass("cache");
            cookies_bloc_lightbox.removeClass("notAccepted");
            // we show the dispatch pop-up
            openMultiShad('popup_dispatch');
        }
    });
}

// Remove FAQ from Cart
if ($(".cart .elem_tunnel.right_side").length > 0) {
    $(".bloc_question").remove();
}

//Demande client : fonction pour permettre l'enregistrement du message personalisé en session
function keepGiftMessage() {
    $('.mot_personnalise_form span').on('click', function () {
        $(this).toggleClass('arrow_close');
    });

    setTimeout(function () {

        /* keep gift message value in session storage */
        // Get the text area element
        let $textAreaMobile = $("#mot_personnalise>textarea");

        // Check if data is already stored in local storage
        if (sessionStorage.getItem("textAreaMobileData")) {
            // If data is stored, retrieve it and set it as the value of the text area
            $textAreaMobile.val(sessionStorage.getItem("textAreaMobileData"));
        }

        // Listen for changes to the text area
        $textAreaMobile.on("input", function () {
            // Store the new data in local storage
            sessionStorage.setItem("textAreaMobileData", $textAreaMobile.val());
        });
    }, 300);
}

// allows the activation of the save function of the personal message to change the quantity adding the wishlist to the basket and the basket to the wishlist
$(window).on("wishToBasket_complete basketToWish_complete", function () {
    keepGiftMessage();
});

// allows the activation of the save function of the personal message to change the quantity adding the wishlist to the basket and the basket to the wishlist
$(window).on("changeQte_success", function (data) {

    // Set event ID for product detail dataLayer
    if (typeof(data.detail.datalayer_object) == 'object') {
        pushIntoDatalayer(data.detail.datalayer_object);
    }
    keepGiftMessage();
    moveSacheterieOption();
});

// Gift Card
let giftCardHelp = "<h3 class='text-besoin-aide is-hidden'>" + Translator.translate('giftcard_offer') + "</h3>";
$('.button.return').after(giftCardHelp);

// Swap div order
$('.stepsList').insertBefore('.resumeList');

// Find the anchor element with the class "cgvLink" and add the target="_blank" attribute
$( ".giftcard_index .cgvLink" ).attr( "target", "_blank" );

$(document).ready(function () {

    //position text for sacheterie
    setTimeout( () => {
        moveSacheterieOption();
    }, 100);

    //Assign first step on multi destination delivery
    if ($('.choix_delivery_multiple.bloc_livraison').length) {
        $('.choix_delivery_multiple.bloc_livraison').find('#infos_livr_multiple').addClass('on_step_one');
    }

    // Change translation on address form on step one
    if ($('body.cart.tunnel').length && $('.choix_delivery_multiple').length) {
        if ($('#adresseForm').length) {
            var formValidationButton = $("#adresseForm .w-submit-button:first");
            var buttonSpan = formValidationButton.find('span');
            buttonSpan.text(Translator.translate('add_address_multidestination'));
        }
    }

    // Select delivery option
    if ($('body.cart.tunnel').length && (!$('body.step_2').length)) {
        var multidestinationBloc = $('.choix_delivery_multiple.bloc_livraison');
        if (multidestinationBloc.length) {
            multidestinationBloc.addClass('active');
        }
    }

    // Fade in page, fix steps modification button and wording appearing on load
    if ($('body.livraison-multiple').length) {
        $('body.livraison-multiple').delay(500).fadeIn(500);
    }

    if ($('body.cart.tunnel').length && ($('.sub_tunnel.basket').length)) {
        keepButtonGiftForProductOnlyMobile();
    }
});

function keepButtonGiftForProductOnlyMobile() {
    $('.elem_tunnel.right_side .order_gift').hide();
    $('.order_gift_form .form_item').css("padding-bottom","0rem");

    $('.sub_tunnel .cart_item_container').each( function () {
        let productId = $(this).attr('data-product-id');

        if (productId > 0) {
            $('.elem_tunnel.right_side .order_gift').show();
            $('.order_gift_form .form_item').css("padding-bottom","3.5rem");
        }
    });
}

// ERESA
/*
 * Gestion des point de vente dans la fiche produit
 * @author Aurélien Renault
 */
var Eresa = {

    boxName: 'e-resaBox',
    box: $('#e-resaBox'),
    content: $('#content_e-resaBox'),
    stores: {}, // La liste de tous les magasin ereservation
    storeSelected: {}, // Le magasin sélectionné
    places: {}, // Google Place
    storeHtml: '', // Le template des magasins
    step: 0, // Le template des magasins
    product: {
        product_id : '',
        color_id : '',
        size_id : ''
    }, // les info du produit
    nbKilometerMax: (typeof eresa_nb_km_max !== 'undefined') ? eresa_nb_km_max : 50,

    // Permet de charger tous les magasins en e-resa (etape 2). Ici pas de carte
    storeInit: function() {
        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            Mustache.parse(Eresa.storeHtml);
        }else{

            // Initialisation du template des stores
            if (this.storeHtml === '') {
                this.storeHtml = $('.store_locator .store')[0].outerHTML;
            }
            Mustache.parse(this.storeHtml);
        }

        var data = {
            'product_id' : Eresa.product.product_id,
            'color_id' : Eresa.product.color_id,
            'size_id' : Eresa.product.size_id,
            'version' : 2,
        };

        var self = this;
        $.ajax({
            type : 'post',
            url : path_relative_root + 'ajax_get_store_ereservation.php',
            data: data,
            success : function (boutique_bookinstore) {

                if (boutique_bookinstore) {
                    self.stores = $.parseJSON(boutique_bookinstore);

                    if (!disable_use_geolocation) {
                        // Pour la restriction google
                        var countries = [];
                        var select = $('select[name="country"]', Eresa.box);
                        var store = '';
                        var length = self.stores.length;
                        // Construction de la liste des pays disponibles
                        for (var i = 0 ; i < length ; i++) {
                            store = self.stores[i];
                            var doublon = countries.find(function (val) {
                                return val === store.iso;
                            });
                            if (doublon === undefined) {
                                countries.push(store.iso);
                                // Pour la construction du select
                                var option = '<option value="' + store.id + '">' + store.country + '</option>';
                                select.append(option);
                            }
                        }

                        // Le trigger sur le select pour bien sélectionner le produit (car le select utilise un plugin)
                        select.trigger('change');

                        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                            navigator.geolocation.getCurrentPosition(function (position) {
                                var pos = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                };

                                if (pos.lat>0 && pos.lng>0) {
                                    self.storeOnPlaceChange(pos);
                                }
                            });

                            var options = {
                                types: ['geocode'],
                                style: google_map_style // dans le cas du chargement de la carte
                            };

                            if (countries && countries.length <= 5) {
                                options.componentRestrictions = {
                                    country: countries
                                };
                            }
                        }else{

                            if (google_map_style !== undefined) {
                                var options = {
                                    types: ['geocode'],
                                    style: google_map_style // dans le cas du chargement de la carte
                                };
                            } else {
                                var options = {
                                    types: ['geocode']
                                };
                            }
                        }

                        if (countries && countries.length <= 5) {
                            options.componentRestrictions = {
                                country: countries
                            };
                        }

                        var eltAutoAdresse = document.getElementById('eresa_autoAddress');
                        // Initialisation de l'autocomplete
                        self.places = new google.maps.places.Autocomplete(eltAutoAdresse, options);

                        google.maps.event.addListener(self.places, 'place_changed', self.storeOnPlaceChange.bind(self));
                        google.maps.event.addDomListener(eltAutoAdresse, 'keydown', function(evt) {
                            // On bloque la touche enter
                            if (evt.keyCode === 13) {
                                evt.preventDefault();
                            }
                        });
                    } else {
                        var storeLocator = $('.store_locator');

                        storeLocator.removeClass('loaded');
                        self.generateStoreBloc(self.stores);

                        // On signale que tous les magasin ont été chargé
                        storeLocator.addClass('loaded');
                    }

                    // On "active" la nouvelle étape
                    $('.step, .step_num').removeClass('actif');
                    $('#step' + Eresa.step + ', .step_num[data-step=' + Eresa.step + ']').addClass('actif');
                } else {
                    alert(Translator.translate('no_store_ereservation'))
                }
            }
        });
    },
    /**
     * Event quand on change d'adresse
     */
    storeOnPlaceChange: function(auto_location) {

        var storeLocator = $('.store_locator');
        var place = this.places.getPlace();

        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            if (typeof(auto_location) != 'undefined') {
                var latitude = auto_location.lat;
                var longitude = auto_location.lng;

            } else {
                var place = this.places.getPlace();
                var latitude = place.geometry.location.lat();
                var longitude = place.geometry.location.lng();
            }
        }else{

            // Récupération de la latitude/longitude de la place trouvé pour le calcul de la distance avec tous les magasins
            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();
        }
        var distance = 0;

        storeLocator.removeClass('loaded');

        var storesOrdonnee = []; // La liste de tous les magasin ereservation ordonné par distance
        // On va parcourir chaque magasin trouvé pour calculer la distance entre le magasin et la position de l'adresse
        $.each(this.stores, function(index, store) {
            // Si la latitude exist
            if (store.lat != 0) {
                // Calcul de la distance avec formule haversine
                // Le calcul est le même que dans la fonction du BO en BDD)
                var radlon1 = (longitude * Math.PI) / 180;
                var radlon2 = (store.lng * Math.PI) / 180;
                var radlat1 = (latitude * Math.PI) / 180;
                var radlat2 = (store.lat * Math.PI) / 180;
                var dlo = (radlon2 - radlon1) / 2;
                var dla = (radlat2 - radlat1) / 2;
                var a = Math.sin(dla) * Math.sin(dla) + Math.cos(radlat2) * Math.sin(dlo) * Math.sin(dlo);
                distance = 6371 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // 6371 rayon de la terre
                store.distance = Math.round(distance * 100) / 100;
            }
            var length = storesOrdonnee.length;
            if (length > 0) {
                // Maintenant on va ordonner la liste des magasins au fur et à mesure
                for (var i = 0 ; i < length ; i++) {
                    // Si la valeur est plus petite que le premier element
                    if (store.distance < storesOrdonnee[i].distance) {
                        storesOrdonnee.splice(i, 0 , store);
                        break;
                    } else if (storesOrdonnee[i+1] !== undefined) { // si on a un élément suivant
                        // Si la distance du magasin est supérieur au courant et inférieur au suivant alors on a trouvé sa place
                        if (store.distance > storesOrdonnee[i].distance && store.distance < storesOrdonnee[i+1].distance) {
                            storesOrdonnee.splice(i+1, 0 , store);
                            break;
                        }
                    } else { // si plus d'élement suivant on met la valeur à la fin
                        storesOrdonnee.push(store);
                    }
                }
            } else {
                storesOrdonnee.push(store);
            }
        });

        // Maintenant qu'on a les magasin dans l'ordre de leur distance on va générer les blocs
        this.generateStoreBloc(storesOrdonnee);

        // On signale que tous les magasin ont été chargé
        storeLocator.addClass('loaded');
    },
    // Pour générer les bloc des magasins
    generateStoreBloc: function(stores) {
        var self = this;
        var storeLocator = $('.store_locator');

        // On vide le bloque
        storeLocator.html('');
        // Les magasins avec au moin 1 stock
        var nbStoreAvailable = 0;
        // Les magasins assez proche
        var nbStoreAvailableNear = 0;
        $.each(stores, function(index, store) {
            // On regarde si il y a un horaire de renseigner
            if (store.schedule !== '') {
                // On va mettre la class actif
                store.showSchedule = 'actif';
            } else {
                store.showSchedule = '';
            }
            store.showQuantity = '';
            store.S_article = '';
            store.styleNoStock = 'display:none !important;';

            if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                if (store.quantity == null) {
                    
                    store.quantity = 0;
                }
            }

            if (store.quantity < 5) {
                if (store.quantity > 1) {
                    // Juste pour mettre un s à articles
                    store.S_article = 's';
                }
                store.showQuantity = 'actif';
            }

            if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                if (store.quantity == 0) {
                    store.showQuantity = 'no_stock';
                    store.styleButton = 'display:none !important;';
                    store.styleNoStock = 'display:block;';
                }
            }

            // Si il n'y a pas assez de stock alors on n'affiche pas le magasin
            if (store.quantity > 0 || typeof(eresa_no_stock) != 'undefined') {
                nbStoreAvailable ++;
                if (store.distance <= self.nbKilometerMax || self.nbKilometerMax < 0 || disable_use_geolocation) {
                    var html = Mustache.render(
                        self.storeHtml,
                        store
                    );
                    $(html).appendTo(storeLocator);
                    nbStoreAvailableNear ++;
                }
            }
        });

        var report = $('<div>', {class: 'js_store_report'});
        if (nbStoreAvailable === 0 || nbStoreAvailableNear === 0) {
            if (nbStoreAvailable === 0) {
                report.html(Translator.translate('store_not_available_quantity'));
            } else if (nbStoreAvailableNear === 0) {
                report.html(Translator.translate('store_not_available_near'));
            }
            $(report).appendTo(storeLocator);
        }

        // L'event pour afficher les horaires
        $('.js_have_horaire_store.actif', storeLocator).on('click', function(event) {
            event.preventDefault();
            var sheduleStore = $(this).next('.schedule_store');
            // Pour afficher les horaires du magasins
            if (!sheduleStore.hasClass('actif')) {
                sheduleStore.addClass('actif');
            } else {
                sheduleStore.removeClass('actif');
            }
        });
    },
    // Dans le cas où on change de couleur
    onChangeColor: function() {
        var color = $('input[name=itm_color]:checked', Eresa.box);
        var size = $('input[name=itm_color]:checked', Eresa.box);
        var productId = color.data('productid');
        var data = {
            'produit_id': productId,
            'couleur_id': color.val(),
            'taille_id': size.val(),
        };
        if (color.data('regroupement') === 1) {
            // Chargement du nouveau produit en cas de regroupement
            this.loadStepEresa(data, 1);
        } else {
            // Changement de l'image
            $('#js_product_bookinstore_img').attr('src', color.data('pic'))
        }
    },
    // Permet de récupérer toutes les informations du produits
    /**
     *
     * @param data
     * @param statut => le statut de chargement (0, 1) les chiffres sont les numéros d'étape (0 => init)
     */
    loadStepEresa: function(data, statut) {
        // Chargement
        $('.steps', Eresa.box).addClass('loading');
        $.ajax({
            url: path_relative_root + 'ajax_bookinstore_produit.php',
            type: 'POST',
            data: data,
            success: function(response){
                try {
                    response = $.parseJSON(response);
                } catch(e){
                    console.error('parseJSON');
                    return;
                }

                if (response.success === 'ok'){

                        var datas = response.datas;
                        var html = $(datas.html);

                        if (statut === 0) {
                            Eresa.content = $('#content_e-resaBox');
                            Eresa.box = $('#e-resaBox');
                            Eresa.content.html(html);
                            // On va déplacer le formulaire de vue JS au bon endroit
                            $('#js_form_infos_bookinstore_vue').append($('#form_eresa'));

                            // Ouverture de la ligthbox
                            modBoxOpen.call(Eresa.box);
                        } else if (statut === 1) {
                            var step1 = $('#step1', html).html();
                            var step4 = $('#step4', html).html();
                            $('#step1', Eresa.content).html(step1);
                            $('#step4', Eresa.content).html(step4);
                        }
                    // }
                    // Chargement
                    $('.steps', Eresa.box).removeClass('loading');

                } else {
                    alert(response.error.msg);
                }
            },
            complete: function(){
                if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
                    $('html, body').animate({ scrollTop: 0 }, $(document).scrollTop() * 0.8);

                    // enveloper taille et couleur dans le div "size-color-wrap"
                    // pour pouvoir ajouter le css "display:flex"
                    $('#e-resaBox.lightbox #step1 .product_info .ligne_form').wrapAll( "<div class='size-color-wrap' />");

                    // Initialisation du template des stores
                    if (Eresa.storeHtml === '') {
                        Eresa.storeHtml = $('.store_locator .store')[0].outerHTML;
                    }

                    if (!Eresa.checkProduct()) {
                        btnLoaderUnloading($("#step1 .form_submit").find(':first'));
                        return false;
                    }

                    Eresa.storeInit();
                }else{
                    $('html, body').animate({ scrollTop: 0 }, $(document).scrollTop() * 0.8);
                }
            }
        });
    },
    // Pour vérifier les information du produit
    checkProduct: function() {
        var error_color = false;
        var error_size = false;
        if (typeof $('input[name=itm_color]:checked', Eresa.box).val() === 'undefined' && typeof $('input[name=itm_color]').val() === 'undefined') {
            error_color = true;
        }
        if ((!force_eresa && typeof $('input[name=itm_size]:checked', Eresa.box).val() === 'undefined') || (force_eresa && typeof $('#prod_taille').val() === 'undefined')) {
            error_size = true;
        }

        if (error_color && error_size) {
            alert(translate('choose_a_size_and_a_color'));
            return false;
        } else if (error_color) {
            alert(translate('js_error_couleur'));
            return false;
        } else if (error_size) {
            alert(translate('js_error_size'));
            return false;
        }
        var color = $('input[name=itm_color]:checked', Eresa.box);
        if (force_eresa) {
            var size = $('input[name=itm_size][value=' + $('#prod_taille').val() + ']', Eresa.box);
            size.prop('checked', true);
        } else {
            var size = $('input[name=itm_size]:checked', Eresa.box);
        }

        Eresa.product.product_id = color.data('productid');
        Eresa.product.color_id = color.val();
        Eresa.product.size_id = size.val();

        if (typeof(Eresa.product.product_id)  === 'undefined') {
            Eresa.product.product_id = $('#produit_id').val();
            Eresa.product.color_id = $('input[name=itm_color]').val()
            Eresa.product.size_id = $('#prod_taille').val();
            $('#TailleIdBookInStore').val(Eresa.product.size_id);
            $('#CouleurIdBookInStore').val(Eresa.product.color_id);
            $('#ProduitIdBookInStore').val(Eresa.product.product_id);
        }
        else{
            $('#TailleIdBookInStore').val(size.val());
            $('#CouleurIdBookInStore').val(color.val());
            $('#ProduitIdBookInStore').val(color.data('productid'));
        }

        // On va attribuer les valeurs pour le récapitulatif (taille et couleur)
        $('#js_color').html(color.data('name'));
        $('#js_size').html(size.data('name'));
        return true;
    },
    // Pour vérifier les informations du magasin
    checkStore: function(btn) {
        var self = this;
        $('#StoreIdBookInStore').val($(btn).data('magasinid'));
        // Enregistrement des informations du magasin (pour l'étape récapitulatif)
        // On va rechercher le magasin selectionné dans la liste
        $.each(this.stores, function(index, store) {
            if (store.id === $(btn).data('magasinid')) {
                self.storeSelected = store;
            }
        });
        return true;
    },

    // Pour vérifier les informations du magasin
    checkClient: function() {

        // Récupération du formulaire
        var form = $('#js_form_eresa');

        var error = false;
        var elt = '';
        // Check de tous les champs obligatoire
        $('.required', form).each(function() {
            elt = $(this);
            if (this.getAttribute('type') === 'checkbox') {
                if (!elt.is(':checked')) {
                    elt.parent().addClass('inputErr');
                    error = true;
                } else {
                    elt.parent().removeClass('inputErr');
                }
            } else if (this.getAttribute('type') === 'radio') {
                var name = elt.attr('name');
                if ($('input[name="' + name + '"]:checked').length === 0) {
                    elt.parent().addClass('inputErr');
                    error = true;
                } else {
                    elt.parent().removeClass('inputErr');
                }
            } else if (this.getAttribute('type') === 'email') {
                var validateEmail =  String(this.value)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
                if (!validateEmail) {
                    elt.parent().addClass('inputErr');
                    error = true;
                } else {
                    elt.parent().removeClass('inputErr');
                }
            } else if (this.getAttribute('type') === 'tel') {
                error = !checkForm('phone', this, $(this).attr('name'));
            } else {
                if (elt.val() === '') {
                    elt.addClass('inputErr');
                    error = true;
                } else {
                    elt.removeClass('inputErr');
                }
            }
        });
        return !error;
    },
    next: function() {
        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {

            $('.step_num[data-step="2"]').on('click', function() {
                Eresa.step = 2;
                $('.step').removeClass('actif');
                $('.step_num').removeClass('actif');
                $('#step' + Eresa.step).addClass('actif');
                // ONGLET : On "active" l'étape 2
                $('.step_num[data-step=' + Eresa.step + ']').addClass('actif');
            });
    
            $('.step_num[data-step="2"]').css({
                cursor: 'pointer'
            });

            if (Eresa.step > 0) {
                // cache l'element "popup_desc" quand c'est pas etape 1
                $('#content_e-resaBox .popup_desc').css('display', 'none');
            }
            // Verification que la couleur et la taille ont bien été choisie
            if (Eresa.step === 2) { // Choix du magasin
                if (!Eresa.checkStore(this)) {
                    return false;
                }
            } else if (Eresa.step === 3) { // Formulaire
                // La vérification du formulaire est faite dans le vuejs (w-pdv-form)
                if (!Eresa.checkClient(this)) {
                    $(this).next('.loader').removeClass('loading');
                    return false;
                }
            }

            // On attend d'avoir la reponse car la prochaine étape ne doit pas encore s'afficher
            if (Eresa.step != 3) {
                // On "desactive" l'ancienne étape
                $('#step' + Eresa.step).removeClass('actif');

                // ONGLET : On "desactive" l'ancienne étape
                $('.step_num[data-step='  + Eresa.step + ']').removeClass('actif');
            }

            Eresa.step++;
            // On "active" la nouvelle étape
            if (Eresa.step!=4) {
                $('#step' + Eresa.step).addClass('actif');
            }

        }else{
            // Verification que la couleur et la taille ont bien été choisie
            if (Eresa.step === 1) {
                if (!Eresa.checkProduct()) {
                    $(this).next('.loader').removeClass('loading');
                    return false;
                }
            } else if (Eresa.step === 2) { // Choix du magasin
                if (!Eresa.checkStore(this)) {
                    $(this).next('.loader').removeClass('loading');
                    return false;
                }
            } else if (Eresa.step === 3) { // Formulaire
                if (!Eresa.checkClient(this)) {
                    $(this).next('.loader').removeClass('loading');
                    return false;
                }
            }

            // On "desactive" l'ancienne étape
            $('#step' + Eresa.step).removeClass('actif');

            // ONGLET : On "desactive" l'ancienne étape
            $('.step_num[data-step='  + Eresa.step + ']').removeClass('actif');

            Eresa.step++;

        }

        if (Eresa.step!=4) {
            // ONGLET : On "active" la nouvelle étape étape
            $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');
        }

        // Au chargement de la 2eme étape on charge les magasin
        if (Eresa.step === 2) {
            if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            }else{
                Eresa.storeInit();
            }
        } else if (Eresa.step === 4) { // validation du choix et envoi des informations
            var form = $('#js_form_eresa').serialize();
            $("#error_limit_quota").hide();
            $("#js_form_eresa button").hide();
            $.ajax({
                url: path_relative_root + 'ajax_bookinstore_client.php',
                type: 'POST',
                data: form,
                dataType: 'json',
                success: function (response){
                    if (response.success) {
                        $('#step' + (Eresa.step-1)).removeClass('actif');

                        // ONGLET : On "desactive" l'ancienne étape
                        $('.step_num[data-step='  + (Eresa.step-1) + ']').removeClass('actif');

                        // ONGLET : On "active" la nouvelle étape étape
                        $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');
                        $('#step' + Eresa.step + ' .confirm').css('display', 'block');
                    } else {
                        if (response.type_error=='limit_exceeded') {
                            Eresa.step--;
                            $("#error_limit_quota").show();
                            $("#error_limit_quota span").html(response.error_msg);
                        } else {
                            $('#step' + Eresa.step + ' .confirm').css('display', 'none');
                            $('#step' + Eresa.step + ' .error').html(response.error_msg).css('display', 'block');
                        }
                    }

                    // On va attribuer les valeurs pour le récapitulatif (taille et couleur)
                    $('#js_color').html(response.product.color.name);
                    $('#js_size').html(response.product.size.name);

                    // Attribution des information du magasin
                    var html = Mustache.render(
                        Eresa.storeHtml,
                        Eresa.storeSelected
                    );
                    $('#js_eresa_store_choice').html(html);

                    // L'event pour afficher les horaires
                    $('.js_have_horaire_store.actif', $('#js_eresa_store_choice')).on('click', function(event) {
                        event.preventDefault();
                        var sheduleStore = $(this).next('.schedule_store');
                        // Pour afficher les horaires du magasins
                        if (!sheduleStore.hasClass('actif')) {
                            sheduleStore.addClass('actif');
                        } else {
                            sheduleStore.removeClass('actif');
                        }
                    });

                    // On "active" la nouvelle étape
                    $('#step' + Eresa.step).addClass('actif');
                }
            });
        } else {
            // On "active" la nouvelle étape
            $('#step' + Eresa.step).addClass('actif');
        }
        $(this).next('.loader').removeClass('loading');
    },
    // Montre la lightbox des points de ventes
    show: function() {
        var produitId = $('#produit_id').val();
        
        var couleurId = $('#prod_couleur').val();
        if (!couleurId) {
            couleurId = $('#prod_couleur').attr('data-id');
        }
        var tailleId = $('#prod_taille').val();
        var data = {
            'produit_id': produitId,
            'couleur_id': couleurId,
            'taille_id': tailleId,
        };
        var missing_value = (!data.produit_id || !data.couleur_id || !data.taille_id);
        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true && missing_value) {
            alert(translate('js_error_size'));
            return false;
        }
        // Chargement du produit dans la lightbox
        Eresa.loadStepEresa(data, 0);

        Eresa.step = 1;

        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            // On ne laisse plus le choix des tailles / couleur deja fait avant
            Eresa.next();
        }

    },
    // Cache la lightbox des points de ventes
    close: function() {

        modBoxClose();
        setTimeout(function() {Eresa.RAZ()}, 1000);
    },

    // Remet à 0 les step
    RAZ: function() {
        // On revient à l'étape 1
        Eresa.step = 1;
        // On désactive tout
        $('.step').removeClass('actif');
        $('.step_num').removeClass('actif');
        // On active l'étape 1
        $('#step' + Eresa.step).addClass('actif');
        // ONGLET : On "active" l'étape 1
        $('.step_num[data-step='  + Eresa.step + ']').addClass('actif');
    }
}

$(document).ready(function() {
    setTimeout(() => {
        $('#user_btn').on('click', function() {
            if ($('.top_search').hasClass('opened')) {
                searchToggle();
            }

            if ($(this).hasClass('connected')) {
                $('#shade').addClass('forAccount');

                if ($('body').hasClass('searching')) {
                    $( "body" ).addClass('search_hidden');
                    $('.block_top_item #form_recherche').hide().removeClass('activated');
                    $('body').removeClass('searching');
                }
            }
        });

        $(document).on('click', function(e) {
            if ($(e.target.parentElement).attr('id') != 'user_btn') {
                $('#shade').removeClass('forAccount');
                $('#shade').removeClass('forMenu');
            }

            if ($(e.target.parentElement).attr('id') != 'search_icon' && $(e.target.parentElement).attr('id') == 'main_menu_btn' && $('body').hasClass('searching')) {
                $( "body" ).addClass('search_hidden');
                $('.block_top_item #form_recherche').hide().removeClass('activated');
                $('body').removeClass('searching');
            }
        });

        $('#main_menu_btn').on('click', function() {
            $('#shade').toggleClass('forMenu');
            
            if ($('#shade').hasClass('forSearch')) {
                $(this).removeClass('forSearch');
            }
        });

    }, 300);

    if ($('#mot_personnalise').length) {
        $('.mot_personnalise_form span').trigger('click');
    }

    //toggle filtre cms page journal
    $('.cms_menu_item.filter .date_filter_title').on("click tap touch", function () {
        $('.cms_menu_item.filter').toggleClass("open");
    });

    if ($('.cms_subcategory_menu').length && ($('.cms_subcategory_menu .swiper-slide').length > 1)) {
        setTimeout(function () {
            var rubrique_swiper = new Swiper('.cms_subcategory_menu', {
                slidesPerView: 'auto',
                spaceBetween: 40,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
            });
        }, 2000);
    }
});

// DATALAYER FUNCTION
function addToCart(rank, qte) {}

function removeFromCart(rank, qte) {}

// Trigger achat from basket gondole Rayon
function triggerAchatExpressV2(productId) {
    $("#prod_info_" + productId + " > div.form_submit.bloc_achat_express > button.button.achat.valid").click();
}

function sendFormUpdateCart() {
    var output = false;
    var value = getInputVal.bind(this);

    if (this.productBatchId != '' && this.productBatchId != undefined) {
        var name = this.titreObjet;
        var unit_price = this.prix;
        var quantity = this.qte;
    } else {
        var name = value('itm_name', '');
        var unit_price = value('itm_unitprice', 0);
        var quantity = value('itm_quantity', 'none');
    }

    var idProduitSize = 0;
    var color_id = 0;
    var size_id = 0;
    var achat_express_input = false;

    if (value('achatExpressV2')) {
        $(this).find('.bloc_achat_express .button.valid').addClass('loading');
        $(this).find('.achat_express').hide();
        // if x-sell activated do not show shadows
        var element =  document.getElementById('item_added');
        if (!$(element).hasClass('open')) {
            $("#shade").removeClass("isExpress").removeClass("visible");
        }

        idProduitSize = value('produit_id');
        color_id = value('itm_color');
        unit_price = value('prixU');
        achat_express_input = true;
    } else {
        color_id = value('itm_colour');
        idProduitSize = value('itm_id');
        var sizes = this.itm_size;
    }

    if (color_id == undefined) {
        color_id = 0;
    }

    var data = {
        id: idProduitSize,
        name: name,
        colour: color_id,
        last_colour: value('itm_lastcolour'),
        size: value('itm_size', 0),
        last_size: value('itm_lastsize'),
        quantity: quantity,
        unit_price: unit_price,
        cart_id: value('cart_id'),
        is_assoc: value('itm_is_assoc', false),
        suffix: value('itm_suffix', ''),
        to_eval: value('to_eval', false),
        page_name: value('page_name', ''),
        not_produit: value('is_not_produit', false),
        configurator: value('config_prod'),
        configurator_text: value('config_text'),
        configurator_color: value('config_color'),
        configurator_color_hex: value('config_color_hex'),
        configurator_color_name: value('config_color_name'),
        is_achat_express_input: achat_express_input
    };

    var listKit = [];

    // Specification ajout produit kit
    if (typeof(produit_kit_lot_nb) !== 'undefined') {
        if (produit_kit_lot_nb == produit_kit_lot_nb_max) {
            data.quantity = 1;

            listKit = $('.qteProd').serialize();

        } else { // Aucun ajout car pas le bon nombre de produit selectionne
            return false;
        }
    }

    this.data = data;

    var is_lightbox_specifique = value('is_lightbox_specifique');

    if (is_lightbox_specifique && is_lightbox_specifique != 'false') { // Ajout panier produit offert specifique
        add_produit_specifique(idProduitSize, color_id, value('itm_size', 0));
    } else {// Ajout panier standard
        $.ajax({
            url: path_relative_root + 'ajax_upd_panier.php',
            type: 'get',
            data: {
                panierId: data.cart_id,
                idprod: data.id,
                idcouleur: data.colour,
                idtaille: data.size,
                qte: data.quantity,
                prixu: data.unit_price,
                configurator: data.configurator,
                batchId: this.productBatchId,
                listKit: listKit
            },

            success: onCartUpdate.bind(this)
        });
    }

    return output;
}

// Update cart
function onCartUpdate(response) {

    var cart_btn, modbox = 'item_error';
    var res = '';

    try {

        res = JSON.parse(response);
    } catch (e) {

        res = response;
    }

    if (!res.error) {

        response = response.trim().split("//");

        if (response[0] != false && response.length >= 0) {

            cart_btn = document.getElementById('cart_btn');

            if (/^\d/.test(this.data.name)) {

                if (response[0] == 1) {

                    response[0] += " lot de";
                } else {

                    response[0] += " lots de";
                }
            }

            response[0] += ' ' + this.data.name;

            if (this.data.size !== 'none') {

                response[0] += ' - Taille ' + (this.data.lbl_size || this.data.last_size);
            }

            if (this.data.colour !== 'none') {

                response[0] += ' - ' + (this.data.lbl_colour || this.data.last_colour);
            }

            if (response[1] == 0) {

                if (cart_btn) {

                    cart_btn.classList.remove('count');
                    cart_btn.removeAttr('data-cart');
                }
            } else if (response[1] != false && response.length > 0) {

                if (cart_btn) {

                    cart_btn.classList.add('count');
                    cart_btn.setAttribute('data-cart', response[1]);
                }
            }

            modbox = 'item_added';

            $(this).trigger('itmAddedToCart');

            if ($(this).closest('#gondole_interest').length && $(this).closest('#gondole_interest').closest('#item_added').length) {
                $('.modbox.open').css('z-index', '100');

                // Check if the div already exists
                if ($('#achatExpress_Tg_in_Tg').length === 0) {
                    $(`
                        <div id="achatExpress_Tg_in_Tg">
                            <p class="modbox_title"> ${Translator.translate('item_added_to_cart')}</p>
                        </div>`
                    ).insertAfter('#item_added');

                    // Set a timeout to remove the div after 10 seconds
                    setTimeout(() => {
                        $('#achatExpress_Tg_in_Tg').fadeOut(300, function() {
                            $(this).remove(); // Remove the element from the DOM
                        });

                        // Check if #item_added.modbox has the class 'open'
                        if (!$('#item_added.modbox').hasClass('open')) {
                            $('.modbox').css('z-index', ''); // Reset z-index to default
                        } else {
                            $('.modbox.open').css('z-index', '600'); // Set z-index if modbox is open
                        }
                    }, 800);
                }

                $('.modbox_buttons .button.cancel').on('click', function () {
                    $('.modbox').css('z-index', '');
                });
            }
        }
    } else {

        $('#' + modbox).find('.modbox_title').html(res.error.msg);
    }

    window.setTimeout(function () {

        $('.loader').removeClass('loading');
    }, 400);

    modBoxOpen.call(document.getElementById(modbox));
}

/**
 * Adds or removes a store from the user's favorites.
 * 
 * @param {number} magasin_id - The ID of the store.
 * @param {number} client_id - The ID of the client.
 * 
 * This function checks the current favorite status of the store. If the store is currently a favorite, it removes the store from the favorites and updates the button to reflect this change. If the store is not currently a favorite, it adds the store to the favorites and updates the button to reflect this change.
 * 
 * The function makes an AJAX request to the server to update the favorite status of the store. The server responds with the new favorite status of the store, which the function uses to update the page.
 */
function addToMyFavorites(magasin_id, client_id) {
    var self = $(this);

    if (client_id == 0 || client_id == undefined || client_id == null) {
        closepdv();
        openMultiShad('box_favorite_store');
        return false;
    }

    let is_favorite_element = document.getElementById('is_favorite_' + magasin_id);
    let is_favorite = (is_favorite_element) ? is_favorite_element.dataset.isFavorite : '0';

    var action = self.attr("data-action");

    // If the action is to destroy, then we are in favorite_stores and we want to delete the entire block
    if (action == "destroy") {
        var act = "del";
    } else {
        // We are in store_details and we need to retrieve the current status of the store to know whether we should delete or add it
        act = (is_favorite == "1") ? "del" : "add";
    }

    let is_store_page = document.body.classList.contains('satellite_store_details');

    $.ajax({
        url: path_relative_root + create_link('ajax_manage_favorite_store'),
        type: "post",
        dataType: "html",
        data: 'act=' + act + '&magasin_id=' + magasin_id + '&client_id=' + client_id,
        success: function(response) {
            if (response) {
                is_favorite = (act == 'add') ? 1 : 0;
                is_favorite_element.dataset.isFavorite = is_favorite;
                response_text = 'choisir_ce_magasin';
                if (act == 'add') {
                    response_text = 'delete_as_favorite_store';
                } else if (act == 'del') {
                    if (is_store_page) {
                        response_text = 'choose_as_favorite_store';
                    }
                }

                let array_buttons = document.getElementsByClassName('favorite_store_button');
                for (let i = 0; i < array_buttons.length; i++) {
                    array_buttons[i].innerText = Translator.translate('choisir_ce_magasin');
                }
                document.getElementById('add_favorite_' + magasin_id).innerText = Translator.translate(response_text);
                renderFavoriteStore(magasin_id, act);
            }
        },
    });
}

$('#send_store_information').on('click', function() {
    $('.modal_shade').addClass('visible');
});

function show_lightbox_send_info() {
    $('#shade').off('click').after($('#ligthbox_send_store_information'));
    $('#shade').stop(true, true).fadeTo("slow", 0.8).queue(function() {
        $('#ligthbox_send_store_information').slideDown("slow").addClass('active');
        $(this).dequeue();
    }).one('click', hide_lightbox_send_info);
} 

function hide_lightbox_send_info() {
    $('.modal_shade').removeClass('visible');
    $('#ligthbox_send_store_information').slideUp("slow").removeClass('active').queue(function() {
        $('#shade').stop(true, true).fadeTo("slow", 0, function() {
            document.getElementById('ligthbox_send_store_information').style.display = 'none';
        });

        document.getElementById('shade').style.display = 'none';
        $('#ligthbox_send_store_information').dequeue();
    });
}

function send_store_information(magasin_id) {

    var client_mail = $('#client_mail').val();
    var client_tel = $('#client_tel').val();
    var token_security = $('#token_security').val();
    var client_tel_international = $('#numero_mobileHidden').val();

    var is_ok = check_send_info_form(client_mail, client_tel);

    if (is_ok && client_mail == '' && ($('#client_tel').intlTelInput("isValidNumber") === false || $('#client_tel').intlTelInput("getNumberType") === 0)) {
        alert('Numero de téléphone incorrect');
        is_ok = false;
    }

    if (is_ok) {

        $.ajax({
            url: path_relative_root + create_link('ajax_send_store_information'),
            type: 'post',
            data: 'magasin_id=' + magasin_id + '&client_tel=' + client_tel_international + '&client_mail=' + client_mail + '&token_security=' + token_security,
            success: function(response) {
                if (response !== 'false') {
                    hide_lightbox_send_info();
                    setTimeout(() => {                    
                        alert(Translator.translate('success_coordonnees'));
                        if($('#alert_box').hasClass('open')) {
                            $('#shad').addClass('visible').css('opacity','1');
                        }
                    }, 100);

                } else {
                    alert(Translator.translate('error_coordonnees'));
                }
            }
        });
    }
}

function check_send_info_form(client_mail, client_tel) {

    if (client_tel == '' && client_mail == '') {
        alert('veuillez rentrer soit un email, soit un numéro de téléphone');
        return false;
    }

    if (client_tel != '' && client_tel.length > 10) {
        alert(Translator.translate('error_checktelephone'));
        return false;
    }

    return true;
}

function sendFormContactByStore(formulaire) {
    var optin_g = ($("input[name='optin']:checked").val());
    var optin_p = ($("input[name='optin_partners']:checked").val());

    var data = {
        'produit_id' : formulaire.produit_id.value,
        'produit_url' : formulaire.produit_url.value,
        'client_nom' : formulaire.nom.value,
        'client_prenom' : formulaire.prenom.value,
        'client_email' : formulaire.email.value,
        'client_telephone' : formulaire.tel.value,
        'client_message' : formulaire.message.value,
        'magasin_id' : formulaire.magasin_id.value,
        'optin_general' : optin_g,
        'optin_partners' : optin_p
    };

    $.ajax({
        url : path_relative_root + create_link('ajax_contactbystore'),
        type : 'post',
        data : data,
        async : false,
        success : function (res) {
            $('#wrapper_search_shop').html(JSON.parse(res));
        }
    });
    return false;
}

/**
 * Displays the point de vente (store) information.
 */
function showpdv() {
    var shade, modbox;
    $.ajax({
        type: "post",
        url: path_relative_root + create_link("ajax_search_shop"),
        success: function (res) {
            if (res) {
                shade = document.querySelector("#shade.modal_shade");
                modbox = document.getElementById("pdvBox");

                let title_box = (document.body.classList.contains('product_page'))
                    ? Translator.translate('store_locator_stock_availabilty')
                    : Translator.translate('choose_favorite_store');
                ;

                $('#pdvBox .popup_title').html(title_box);

                // Make sure modbox will show on top of shad
                $(shade).after(modbox);

                // Inject store locator into modbox
                $(".store_locator").html(res);

                // magasin_gmap.js
                initialize();

                //destroy the overlay scrollbars on #list-store

                // Show modbox and make sure closing routine won't fire more than once
                $(modbox).addClass("actif");
                $(shade).addClass("actif");

                $(shade).on("click touch", closepdv);
                $("#pdv_load").removeClass("loading");
            }
        },
    });
}

/**
 * Closes the PDV (Point de Vente) box.
 * @param {string} from - The source from which the function is called.
 */
function closepdv(from) {
    var shade = document.querySelector("#shade.modal_shade");
    var modbox = document.getElementById("pdvBox");

    $(modbox).removeClass("actif");
    $(shade).removeClass("actif");

    $(shade).on("click touch", closepdv);

    if (from == "fiche produit") {
        setTimeout(function () {
            $("#content_pdvBox_new").hide();
            $("#content_pdvBox").show();
            $(modbox).removeClass("showForm");
            $(modbox).removeClass("thanks");
        }, 200);
    }
}

/**
 * Renders the favorite store based on the provided magasin_id and action.
 *
 * @param {string} magasin_id - The ID of the store.
 * @param {string} [act='add'] - The action to perform. Default is 'add'.
 */
function renderFavoriteStore(magasin_id, act = 'add') {
    let is_product_page = document.body.classList.contains('product_page');
    let is_customer_page = document.body.classList.contains('customer');

    if (is_product_page) {
        if (act == 'add') {
            $('#favorite_store .stock_availability').show();
            $('#favorite_store .separator').show();
            $('#favorite_store .check_availability').hide();
            $('#favorite_store .change_store').show();
            let store_name = $('[data-info="' + magasin_id + '"] .name a').text().split(' - ')[0];
            let stock_status = $('[data-info="' + magasin_id + '"] .stock_status_txt').text();
            let class_list = $('[data-info="' + magasin_id + '"] .stock_status').attr("class");
            $('#favorite_store .stock_status_txt').text(stock_status + ' | ' + store_name)
            $('#favorite_store .stock_status').attr('class', class_list);
        } else if (act == 'del') {
            $('#favorite_store .stock_availability').hide();
            $('#favorite_store .separator').hide();
            $('#favorite_store .check_availability').show();
            $('#favorite_store .change_store').hide();
        }
    } else if (is_customer_page) {
        if (act == 'add') {
            $('#favorite_store .choose_store').hide();
            $('#favorite_store .customer_adress').show();
            let store_name = $('[data-info="' + magasin_id + '"] .name a').text().split(' - ')[0];
            let store_address = $('[data-info="' + magasin_id + '"] .address a').text();
            let store_phone = $('[data-info="' + magasin_id + '"] .phone_store').text();
            $('#favorite_store .store_name').text(store_name)
            $('#favorite_store .address').text(store_address);
            $('#favorite_store .phone_number').text(store_phone);
        } else if (act == 'del') {
            $('#favorite_store .choose_store').show();
            $('#favorite_store .customer_adress').hide();
        }
    }
}

function customizeMapDeliveryContent() {
    $('.wrapper_search_shop').overlayScrollbars({});
}

// We select the first postal delivery option by default
$(document).ajaxComplete(function() {
    if ($('body.cart.step_2').length) {
        if ($(".choix_delivery_postal:first .ship_radio").length) {
            $(".choix_delivery_postal:first .ship_radio").click();
        }
    }
});

// Function to handle the display of the address recap
$(window).on("changeRelay_complete", function(data) {
    let conveyorId = data.detail.conveyor_id;

    let storeId = data.detail.store_id;

    if (conveyorId == '24' && storeId != 0) {
        updateStoreDeliveryData(storeId, conveyorId);
    }
});
